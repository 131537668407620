import { createSlice } from "@reduxjs/toolkit"

export const counterSlice = createSlice({
  name: "auth",
  initialState: {
    loggedIn: false,
    accessToken: null,
    userId: null,
    isVerified: false,
    enterpriseId: -1,
    idToken: null,
    inTake: false,
    scheduling: false,
    avatar: "",
    enterpriseName: "My Company",
    email:''
  },
  reducers: {
    updateLoginStatus: (state, action) => {
      state.loggedIn = action.payload.loggedIn
      state.avatar = action.payload.avatar,
      state.accessToken = action.payload.accessToken,
      state.userId = action.payload.userId,
      state.isVerified = action.payload.isVerified,
      state.enterpriseId = action.payload.enterpriseId,
      state.idToken = action.payload.idToken,
      state.inTake = action.payload.inTake,
      state.scheduling = action.payload.scheduling
      state.email = action.payload.email
    },
    updateSchedule: (state, action) => {
      return {
        ...state,
        scheduling: action.payload.scheduling,
      }
    },
    updateIntake: (state, action) => {
      return {
        ...state,
        inTake: action.payload.inTake,
      }
    },
    updateAvatar: (state, action) => {
      return {
        ...state,
        avatar: action.payload.avatar,
      }
    },
    updateEnterpriseName: (state, action) => {
      return {
        ...state,
        enterpriseName: action.payload.enterpriseName,
      }
    },
    updateEmail: (state, action) => {
      return {
        ...state,
        email:action.payload.email
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  updateLoginStatus,
  updateSchedule,
  updateIntake,
  updateAvatar,
  updateEmail,
  updateEnterpriseName,
} = counterSlice.actions

export default counterSlice.reducer
