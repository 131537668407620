import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import "@popperjs/core/dist/umd/popper.min.js"
 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 


import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import store from './src/redux/store'
import React from "react"
import { PersistGate } from "redux-persist/integration/react"

import "./global.css"

export const onInitialClientRender = () => {
  document.getElementById("Loading_screen").remove()
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <Provider store={store} {...props}>
      <PersistGate
        loading={
          <div id="Loading_screen" className="Loader center">
            <div className="ring"></div>
            <span>loading...</span>
          </div>
        }
        persistor={persistStore(store)}
      >
        {element}
      </PersistGate>
    </Provider>
  )
}
